import { isNumber, parseInt, toNumber, isNaN, isEmpty } from "lodash";

import { apolloClient } from "src/apollo-client";
import { QUERY_MERCHANTS } from "src/ecommerce/graphql/queries/query-merchants";
import { QUERY_SUPPLIERS } from "src/ecommerce/graphql/queries/query-suppliers";
import { QUERY_PRODUCTS } from "src/ecommerce/graphql/queries/query-products";

import { create } from "src/ecommerce/api/order";
import { Ecommerce } from "../../components/Ecommerce";
import { RentFindProduct } from "../rent/RentFindProduct";
import { RentLayout } from "../layouts/RentLayout";
import { Header } from "src/common/components/partials/Header";
import ContactMessage from "../partials/ContactMessage";

export function RentPage() {
  return (
    <Ecommerce>
      <Header activeStep={1} />
      <RentLayout>
        <div className="row justify-content-center">
          <div className="col-24 col-md-18 rce px-lg-0">
            <h1 className="fs-xl fw-semibold mt-0 mb-lg-6">Wyszukaj produkt</h1>
            <RentFindProduct />
            <ContactMessage />
          </div>
        </div>
      </RentLayout>
    </Ecommerce>
  );
}

export async function getServerSideProps(context) {
  const query = context.query;
  const productType = query?.productType;
  const productData = {};

  try {
    if (productType === "dynamic") {
      await prepareDynamicProductData(query, productData);
    } else {
      await prepareData(query, productData);
    }
  } catch (error) {
    console.error("Prepare params error: ", error.message);
    return {
      notFound: true,
    };
  }

  if (!isEmpty(productData)) {
    const order = await create({
      data: {
        status: 1, // Draft
        ...productData,
      },
    });

    if (order && order?.data?.attributes?.code) {
      return {
        redirect: {
          destination: `/rent/${order?.data?.attributes?.code}/period`,
        },
      };
    } else {
      return {
        notFound: true,
      };
    }
  }

  return { props: {} };
}

const prepareData = async (query, productData) => {
  const sku = query?.sku;
  const rentPeriod = query?.rentPeriod ? parseInt(query?.rentPeriod) : null;
  const merchantName = query?.merchant;
  const supplierName = query?.supplier;

  if (merchantName && supplierName && sku && rentPeriod) {
    const merchant = await queryMerchantByName(merchantName);
    const merchantId = merchant?.id ? parseInt(merchant?.id) : null;

    if (!isNumber(merchantId)) {
      throw new Error("Invalid query parameters: merchant not found");
    }

    const supplier = await querySupplierByName(supplierName);
    const supplierId = supplier?.id ? parseInt(supplier?.id) : null;

    if (!isNumber(supplierId)) {
      throw new Error("Invalid query parameters: supplier not found");
    }

    const product = await queryProductBySupplierAndSKU(supplierId, sku);
    const productId = product?.id ? parseInt(product?.id) : null;

    if (!isNumber(productId)) {
      throw new Error("Invalid query parameters: product not found");
    }

    productData.merchant = merchantId;
    productData.lines = [{ productId: productId, rentPeriod: rentPeriod }];
  }
};

const prepareDynamicProductData = async (query, productData) => {
  const sku = query?.productSKU;
  const rentPeriod = query?.rentPeriod ? parseInt(query?.rentPeriod) : null;
  const merchantName = query?.merchant;
  const productName = query?.productName;
  const productCategory = query?.productCategory;
  const productPrice = toNumber(query?.productPrice);

  if (
    merchantName &&
    sku &&
    rentPeriod &&
    productName &&
    productCategory &&
    productPrice &&
    !isNaN(productPrice) &&
    productPrice > 0.0
  ) {
    const merchant = await queryMerchantByName(merchantName);
    const merchantId = merchant?.id ? parseInt(merchant?.id) : null;

    if (!isNumber(merchantId)) {
      throw new Error(`Merchant: ${merchantName} not found`);
    }

    productData.productType = "dynamic";
    productData.merchant = merchantId;
    productData.lines = [
      { sku: sku, name: productName, category: productCategory, amount: productPrice, rentPeriod: rentPeriod },
    ];
  } else {
    throw new Error("Invalid query parameters");
  }
};

const queryMerchantByName = async (name) => {
  const filters = {
    name: {
      eqi: name,
    },
  };

  const { data } = await apolloClient.query({
    query: QUERY_MERCHANTS,
    variables: {
      filters: filters,
    },
    fetchPolicy: "no-cache",
  });

  return data?.merchantMerchants?.data?.[0];
};

const querySupplierByName = async (name) => {
  const filters = {
    name: {
      eqi: name,
    },
  };

  const { data } = await apolloClient.query({
    query: QUERY_SUPPLIERS,
    variables: {
      filters: filters,
    },
    fetchPolicy: "no-cache",
  });

  return data?.suppliers?.data?.[0];
};

const queryProductBySupplierAndSKU = async (supplierId, sku) => {
  const filters = {
    offers: {
      and: {
        supplier: {
          id: {
            eq: supplierId,
          },
        },
        sku: {
          eq: sku,
        },
      },
    },
  };

  const { data } = await apolloClient.query({
    query: QUERY_PRODUCTS,
    variables: {
      filters: filters,
    },
    fetchPolicy: "no-cache",
  });

  return data?.products?.data?.[0];
};
