import cookie from "cookie";
import { isEmpty, parseInt } from "lodash";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { gql, useLazyQuery } from "@apollo/client";
import Image from "next/image";
import { create } from "src/ecommerce/api/order";
import { styled } from "stitches.config";
import { FieldFloatingText } from "./../../../common/components/ui/form/field/FieldFloatingText";
import { sendGTMEvent } from "@next/third-parties/google";

import MagnifierIcon from "public/svg/icons/magnifier.svg";
import LoaderIcon from "public/svg/icons/loader.svg";
import { isAbsoluteUrl } from "src/utils/format/is-absolute-url";
import SmileIcon from "../../../assets/svg/smile.svg";

const QUERY_PRODUCTS = gql`
  # Write your query or mutation here
  query Products($filters: ProductFiltersInput) {
    products(filters: $filters) {
      data {
        id
        attributes {
          name
          price
          mediaExpertId
          rentPrices {
            period
            percentage
            addition
            calculated
            regular
            discounted
          }
          tags {
            data {
              id
              attributes {
                name
              }
            }
          }
          images {
            data {
              id
              attributes {
                formats
              }
            }
          }
        }
      }
    }
  }
`;

export function RentFindProduct() {
  const router = useRouter();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const [getProducts, { data: dataProduct }] = useLazyQuery(QUERY_PRODUCTS);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const searchProduct = (data) => {
    getProducts({
      variables: {
        filters: {
          mediaExpertId: {
            eq: data.product_code,
          },
        },
      },
    });
  };

  const addToOrder = async (product) => {
    const data = {
      status: 1, // Draft
      lines: [
        {
          productId: product?.id,
        },
      ],
      tags: product?.attributes?.tags?.data?.map((tag) => parseInt(tag?.id)) || [],
    };

    populateCoupon(data);

    setLoadingCreate(true);

    const order = await create({
      data: data,
    });

    if (order && order?.data?.attributes?.code) {
      const orderCode = order?.data?.attributes?.code;
      sendGTMEvent({ event: "form_checkout_find_product_submit", product: product });
      router.push(`/rent/${orderCode}/period`);
    }

    setLoadingCreate(false);
  };

  useEffect(() => {
    if (dataProduct?.products?.data && isEmpty(dataProduct?.products?.data)) {
      setError("product_code", {
        type: "not_found",
        message: `Ups, nie mamy tego produktu. Sprawdź, czy wpisany przez Ciebie kod produktu jest prawidłowy. Kod jest poprawny, a produkt wciąż jest nieaktywny? Możliwe, że aktualnie nie znajduje on się w naszej ofercie. Produkty, które możesz wynająć, oznaczone są logo Gleevery. Problem się powtarza? Napisz do nas na <a href="mailto:ook@gleevery.com" class="text-danger text-decoration-underline">ook@gleevery.com</a> lub zadzwoń <a href="tel:+48573503955" class="text-danger text-decoration-underline">+48 573 503 955</a>. Z chęcią pomożemy!`,
      });
    }
  }, [setError, dataProduct]);

  const cmsUrl = process.env.NEXT_PUBLIC_CMS_URL;
  const product = dataProduct?.products?.data[0];
  const productImage = product?.attributes?.images?.data[0]?.attributes?.formats?.thumbnail;
  const productImageUrl = productImage
    ? isAbsoluteUrl(productImage?.url)
      ? productImage?.url
      : cmsUrl + productImage?.url
    : null;
  const productName = product?.attributes?.name;

  const Button = () => {
    return (
      <button type="submit" className="btn btn-lg px-5 btn-primary" data-test-search-button>
        <MagnifierIcon width={20} height={20} fill="#fff" />
      </button>
    );
  };

  return (
    <>
      <div className="mb-6">
        <form onSubmit={handleSubmit(searchProduct)} noValidate>
          <FieldFloatingText
            {...register("product_code", { required: true })}
            label={"Wpisz kod produktu"}
            errors={errors.product_code}
            size={"lg"}
            group={true}
            groupAfterComponent={Button}
          />
        </form>
      </div>
      {product && (
        <div className="rce border rounded-3 p-6" data-test-product>
          <div className="row align-items-center">
            <div className="col-8 col-md-4 px-lg-0">
              {productImage ? (
                <ProductImageContainer>
                  <ProductImage
                    src={productImageUrl}
                    width={productImage?.width}
                    height={productImage?.height}
                    priority
                    sizes="(max-width: 540px) 130px, (max-width: 800px) 140px, 420px"
                    layout="responsive"
                    alt="rental product image"
                  />
                </ProductImageContainer>
              ) : (
                <ProductImageContainer className="d-flex justify-content-center">
                  <SmileIcon width="48px" height="100%" />
                </ProductImageContainer>
              )}
            </div>

            <div className="col-16 col-md mb-lg-0 mb-4 mt-lg-0 mt-3  ps-0" data-test-product-header>
              <p className="fs-m fw-semibold m-0">{productName}</p>
            </div>
            <div className="col-24 col-md-auto mt-lg-0 mt-5">
              <button
                className="btn btn-lg btn-primary fw-semibold text-white px-6 ms-auto"
                onClick={() => {
                  addToOrder(product);
                }}
                disabled={loadingCreate}
                data-test-product-rent-button
              >
                Wynajmij
                {loadingCreate && <LoaderIcon className={"ms-3 me-n3"} width={24} height={24} fill="#fff" />}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function populateCoupon(data) {
  const cookies = cookie.parse(document.cookie);

  if (cookies._gl_coupon && cookies._gl_coupon.length > 0) {
    data.coupon = cookies._gl_coupon;
  }
}

const ProductImage = styled(Image, {
  objectFit: "contain",
  objectPosition: "center",
});

const ProductImageContainer = styled("div", {
  span: {
    maxHeight: "80px",
  },
});
